import Header from "./Components/Header";
import Main from "./Components/Main";
import Footer from "./Components/Footer/Footer";

import "./App.css"

function App() {
  return (
    <div className="App">
    <Header />
    <Main />
    <Footer />     
    </div>
  );
}

export default App;
